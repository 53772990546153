import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import type { PageData } from '@api/hooks/types';
import type { StaticPath } from '@api/mock/staticPaths';
import { HeadBase, PageFlow } from '@layout';
import { getPaths, getPageData, getComponentsData, usePageData } from '@api';
import { STATIC_PATHS, prepareApiUrl, trimSlash } from '@utils';
import { mocks } from '@api/mock';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export interface PageProps {
	fallback: PageData;
	endpoint?: string | null;
	needMock?: boolean;
}

const Page: NextPage<PageProps> = ({ endpoint = null, needMock }) => {
	/*
	 * Получение данных через SWR-хук (из контекста или запросом)
	 * Подробнее: https://swr.vercel.app/ru/docs/with-nextjs
	 */
	const { data } = usePageData(endpoint, needMock);
	const { push } = useRouter();
	
	return (
		<>
			<HeadBase meta={data?.meta} />
			<PageFlow data={data?.items} />
		</>
	);
};

export const getStaticPaths: GetStaticPaths = async () => {
	/*
	 * Получение статических путей страниц с языковым кодом
	 * @paths массив путей, если в getPaths передать аргументом url, запросит с него, если нет, возьмет из локального массива
	 */
	const paths = await getPaths(STATIC_PATHS);
	const pathsMap = paths.map((item: StaticPath) => {
		const routes = trimSlash(item.path).split('/');
		const locale = item.locale || (item.path.match(/^\/en/) ? 'en' : 'ru');
		return {
			params: { slug: routes },
			locale,
		};
	});

	/*
	 * @paths массив статических путей вида [{ params, locale }]
	 * @fallback blocking - для обработки случаев, когда роута нет в списке статических путей
	 * Подробнее: https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
	 */
	return { paths: pathsMap, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
	try {
		const slug = params?.slug;
		const pathname = '/' + (Array.isArray(slug) ? slug.join('/') : slug || 'index');

		const page = prepareApiUrl(pathname, locale, '/pages');
		const components = prepareApiUrl('/components', locale);

		/*
		 * Страницы на моках
		 * @mockKeys ключи в виде url-ов страниц
		 * @isMockPage текущий роут имеет моки в списке
		 */
		const mockKeys = Object.keys(mocks);
		const isMockPage = mockKeys.some((key) => key === page);

		/*
		 * Получение данных
		 * @pageData данные страницы (мета и контент)
		 * @componentsData данные компонентов (шапка, подвал, корзина)
		 */
		const pageData = await getPageData(page, isMockPage);
		const componentsData = await getComponentsData(components);
		/*
		 * Если данных нет, редирект на 404
		 */
		if (!pageData || !componentsData) {
			return { notFound: true, revalidate: 1 };
		}

		return {
			props: {
				/*
				 * @fallback: проброс данных в контекст SWR по ключам: https://swr.vercel.app/ru/docs/with-nextjs
				 * @endpoint: роут для получения данных страницы
				 * @needMock: требовать мок вместо запроса
				 */
				fallback: {
					[components]: componentsData || {},
					[page]: pageData,
				},
				endpoint: page,
				needMock: isMockPage,
			},
			revalidate: 60,
		};
	} catch (error) {
		return { notFound: true, revalidate: 1 };
	}
};

export default Page;
